<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Campaign Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
            <v-toolbar dark class="panel1"><v-toolbar-title>Info</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                <v-form ref="mainForm">
                    <v-container class="pt-2">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field 
                                    outlined 
                                    v-model="form.earned_campaign_id" disabled label="ID">
                                </v-text-field>
                                <v-text-field outlined v-model="form.ec_code" label="Code"></v-text-field>
                                <v-text-field outlined v-model="form.ec_name" label="Name"></v-text-field>
                                <v-textarea 
                                    v-model="form.ec_description" 
                                    label="Description" 
                                    outlined>
                                </v-textarea>
                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.start_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.end_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>
                                <v-select
                                    outlined
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                ></v-select>

                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
                </v-card>
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card>
                    <v-container class="pt-2">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field disabled v-for="info in  systemInfo" :key="info.name" outlined v-model="form[info.fieldName]" :label="info.name"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApEarnedService from '@/services/ApEarnedService'
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Catalogue',disabled: false,href:'/catalogue',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            status_list:[],
           
            form:[],
            logo:'',

            cpFiles:{
                file_type:'image',
                route_type:'catalogues',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            systemInfo:[
                {name:"Created Date",fieldName:"created_date"},
                {name:"Created At",fieldName:"created_at"},
                {name:"Created By",fieldName:"created_by"},
                {name:"Updated Date",fieldName:"updated_date"},
                {name:"Updated At",fieldName:"updated_at"},
                {name:"Updated By",fieldName:"updated_by"},
            ]

    }
  },
  async created() {
      this.form.earned_campaign_id = this.$route.params.earned_campaign_id;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        let title = 'System';
        this.form = {login_id: "",earned_campaign_id: this.form.earned_campaign_id}
        try{
            ApEarnedService.view_earned_campaign(this.form).then((res) => {
                this.form = res.data.data;
                this.status_list = res.data.statusList;                  
            }).catch((e)=>{
                    //console.log(e.response);
                if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
                ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
        } 
    },
    async save(){
       this.form.login_id = ''
        let title = 'Update Pts Campaign';
        if(this.$refs.mainForm.validate()) {
            try{
                ApEarnedService.update_earned_campaign(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success');
                }).catch((e)=>{
                        //console.log(e.response);
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                })
                    ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
            }             
        }  
    },
    
    uploadDone(e){
        //console.log(e);
        this.form.file_url = e.fileUrl;
        //console.log("this." + e.caller + "='" + e.fileName + "'");
        eval("this." + e.caller + "='" + e.fileName + "'");
        //this.form.logo_file_name = e.fileName;
        //console.log(e.caller);
    },

    showUpload(uploadType){
        //console.log('showupload');
        this.cpFiles.file_type = 'image';
        this.cpFiles.route_type = 'catalogues';
        this.caller = uploadType;
        //console.log(uploadType)
        this.cpFiles.showDialog = true;
    },
    dlgClose(){
        this.visible = false;        
    },
  }
}
</script>